import { createApp, h, defineAsyncComponent } from "vue"
//import { createPinia } from "pinia"
import {createInertiaApp, router, usePage} from '@inertiajs/vue3'
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m"
import NProgress from 'nprogress'
import Pusher from 'pusher-js'
import { popups } from './Stores/message.js'

window.Pusher = Pusher;


/* From the FontAwesome Docs */
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

//import i18n from './Plugins/i18n'

import { LoadingPlugin, useLoading } from 'vue-loading-overlay'
const ZLoading = defineAsyncComponent(() =>
    import('@/Components/Elements/ZLoading')
)

const appName = "Stompis"

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    progress: false,
    setup({ el, App, props, plugin }) {
        /*if (props.initialPage.props.auth.user) {
            if (props.initialPage.props.auth.user.settings) {
                if (props.initialPage.props.auth.user.settings.theme) {
                    vuetifyThemeDefault.theme.defaultTheme = props.initialPage.props.auth.user.settings.theme
                }
            }
        }*/

        //const pinia = createPinia()

        const VueApp = createApp({ render: () => h(App, props) });

        // set window as a global property
        VueApp.config.globalProperties.window = window
		VueApp.provide('vuetifyLoaded', { value: false });

        VueApp
            .use(plugin)
            .use(LoadingPlugin)
            //.use(i18n)
            //.use(pinia)
            .use(ZiggyVue, Ziggy)
            .mount(el)

		// Enable pusher logging - don't include this in production
		// Pusher.logToConsole = true

		let pusher = new Pusher((import.meta.env.VITE_PUSHER_APP_KEY || `be6730377d8824a46503`), {
			cluster: (import.meta.env.VITE_PUSHER_APP_CLUSTER || `eu`),
			channelAuthorization: {
				endpoint: route(`web-socket.authenticate`),
				headers: { "X-CSRF-Token": usePage().props.csrf_token },
			},
		})

		if (usePage().props.auth.user) {
			let channel = pusher.subscribe('private-user.' + usePage().props.auth.user.hash_id);
			channel.bind('achievement-reached', function (data) {
				popups.push(data)
			})
		}
    },
})

let timeout = null

router.on('start', () => {
  timeout = setTimeout(() => NProgress.start(), 150)})

router.on('progress', (event) => {
  if (NProgress.isStarted() && event.detail.progress.percentage) {
    NProgress.set((event.detail.progress.percentage / 100) * 0.9)
  }
})

router.on('finish', (event) => {
  clearTimeout(timeout)
  if (!NProgress.isStarted()) {
    return
  } else if (event.detail.visit.completed) {
    NProgress.done()
  } else if (event.detail.visit.interrupted) {
    NProgress.set(0)
  } else if (event.detail.visit.cancelled) {
    NProgress.done()
    NProgress.remove()
  }
})
